import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import Pagination from "../components/Pagination"
import ArticleListItem from "../components/ListItems/ArticleListItem"
import PageHeader from "../components/PageHeader"

const TagsArticleList = ({ pageContext, data, location }) => {
  const { currentPage, numPages } = pageContext

  return (
    <Layout>
      <Metas title={pageContext.name} />
      <PageHeader title="Actualités" subtitle="Vivre à Coucy" />
      <section className="section-bottom page-content">
        <div className="container mx-auto px-4">
          {pageContext.tags && (
            <div className="articles-themes mt-4">
              <div className="flex flex-col flex-wrap lg:flex-row lg:items-center -mx-2">
                <span className="px-2 lg:mb-2">
                  <Link
                    to={`/actualites/`}
                    className="inline-block p-4 text-sm uppercase"
                  >
                    Tous
                  </Link>
                </span>
                {pageContext.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="px-2 lg:mb-2 border-l border-primary"
                  >
                    {parseInt(pageContext.tid) === parseInt(tag.id) ? (
                      <span className="inline-block p-2 text-sm uppercase text-primary">
                        {tag.name}
                      </span>
                    ) : (
                      <Link
                        to={`/actualites${tag.path}`}
                        className="inline-block p-2 text-sm uppercase text-gray-dark"
                      >
                        {tag.name}
                      </Link>
                    )}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div className="columns grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mt-12">
            {data.articles.nodes.map((node, index) => (
              <div key={index} className={`column`}>
                <ArticleListItem content={node} />
              </div>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </div>
      </section>
    </Layout>
  )
}

export default TagsArticleList

export const query = graphql`
  query actualiteTagsListQuery($tid: Int!, $skip: Int!, $limit: Int!) {
    articles: allNodeArticle(
      filter: {
        status: { eq: true }
        relationships: { field_tags: { drupal_internal__tid: { eq: $tid } } }
      }
      sort: { fields: created, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        created
        path {
          alias
        }
        body {
          value
          summary
        }
        relationships {
          image: field_image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 332
                  maxHeight: 208
                  cropFocus: CENTER
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tags: field_tags {
            name
            color: field_color
            path {
              alias
            }
          }
        }
      }
    }
  }
`
